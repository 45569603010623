/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {
    AOS.init({
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 800, // values from 0 to 3000, with step 50ms
        easing: 'ease', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: true, // whether elements should animate out while scrolling past them
        anchorPlacement: 'center-center', // defines which position of the element regarding
    });
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                $hamby = $('.hamburger:not(".hamby-modal")');
                $hambymodal = $('.hamby-modal');
                $nav = $('.nav-primary');

                $hamby.on('click', function (e) {
                    $(this).toggleClass('is-active');
                    $nav.toggleClass('is-active');
                    jQuery('html').toggleClass('no-scroll');
                });

                $('a[href*="#"]')
                    .not('[href="#"]')
                    .not('[href="#0"]')
                    .click(function (event) {
                        // On-page links
                        if (
                            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                            &&
                            location.hostname == this.hostname
                        ) {
                            // Figure out element to scroll to
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                            // Does a scroll target exist?
                            if (target.length) {
                                // Only prevent default if animation is actually gonna happen
                                event.preventDefault();
                                $('html, body').animate({
                                    scrollTop: target.offset().top - 120
                                }, 1000);

                                $nav.removeClass('is-active');
                                $hamby.removeClass('is-active');
                                jQuery('html').removeClass('no-scroll');
                            }
                        }
                    });

                $termsDialog = jQuery('div.terms-of-use');

                jQuery('.terms-btn-footer').on('click', function (e) {
                    $termsDialog.addClass('is-active');
                    $hambymodal.addClass('is-active');
                    $termsDialog.css('margin-top', jQuery('header.banner').height());
                    jQuery('html').addClass('no-scroll');

                    jQuery('.hamby-modal').on('click', function (e) {
                        $hambymodal.removeClass('is-active');
                        $termsDialog.removeClass('is-active');
                        jQuery('html').removeClass('no-scroll');
                    });
                });

                /*var velocity = 0.2;

                function updateImg() {
                    if ($(window).outerWidth() > 768) {
                        var pos = $(window).scrollTop();
                        $('.bgimage-head').each(function () {
                            var $element = $(this);
                            var height = $element.height();
                            var origPos = $element.height() - ($(window).outerHeight() / 2) + 100;
                            $(this).css('backgroundPosition', '50% ' + Math.round(-origPos + (height - pos) * velocity) + 'px');
                        });
                    }
                }*/

                // $(window).bind('scroll', updateImg);

                $(window).resize(function () {
                    if ($(window).outerWidth() < 768) {
                        $('.bgimage-head').css('backgroundPosition', '50% 50%');
                    }
                });

                /*var prevScrollpos = window.pageYOffset;
                window.onscroll = function() {
                    var currentScrollPos = window.pageYOffset;
                    if ( currentScrollPos < 500  ) {
                        console.log(currentScrollPos);
                        document.getElementById("header").style.top = "0";
                    } else {
                        document.getElementById("header").style.top = "-" + jQuery('#header').outerHeight() + 'px';
                    }
                    prevScrollpos = currentScrollPos;
                };*/

                jQuery('.carousel').slick({
                    arrows: false,
                    dots: true,
                    infinite: true,
                    speed: 1000,
                    autoplay: true,
                    autoplaySpeed: 5000,
                });


               /* $("#input_1_3").keyup(function () {
                    var text = jQuery("#input_1_3").val();

                    // will remove the blank lines from the text-area
                    text = text.replace(/^\s*[\r\n]/gm, "");

                    //It will split when new lines enter
                    var lines = text.split(/\r|\r\n|\n/);

                    var count = lines.length; //now you can count thses lines.
                    console.log(count);
                    if (count != undefined) {
                        jQuery("#input_1_3").css('height', count * 50)
                    }
                });*/


                /*var el = document.querySelector("#headimg");
                console.log(el);
                console.log('el');
                el.addEventListener("mousemove", function(e) {
                  el.style.backgroundPositionX = -e.offsetX + "px";
                  el.style.backgroundPositionY = -e.offsetY + "px";
                });*/
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {

            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
